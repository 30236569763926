import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import elexModalBannernew from "../elexModalBannernew.jpg";
import { Navigate, useNavigate } from "react-router-dom";

const ExchangeCarousel = () => {
  const [modalIsOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    setIsOpen(true);

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const handleBannerClick = () => {
    navigate("/exchange");
  };
  const data = [
    {
      image: "assets/img/banner-4/elex-banner-lg.jpg",
    },
    // {
    //   image: "assets/img/banner-4/CG-Elex-Beko-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/MideaBanner-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/CG-Elex-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/CG-Elex-SDA-2081.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/banner-new-year.jpg",
    // },
    // {
    //   image: "assets/img/banner-4/re-elex-banner-1.jpg",
    // },
    {
      image: "assets/img/banner-4/re-elex-banner-2.jpg",
    },
    {
      image: "assets/img/banner-4/re-elex-banner-3.jpg",
    },
    {
      image: "assets/img/banner-4/CG-Elex1.jpg",
    },
    {
      image: "assets/img/banner-4/CG-Elex2.jpg",
    },
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div ref={modalRef} className="main-carousel">
      {modalIsOpen && (
        <Modal
          ref={modalRef}
          isOpen={modalIsOpen}
          onClose={handleClose}
          className="mobileko"
        >
          <div
            style={{ position: "relative", cursor: "pointer" }}
            onClick={handleBannerClick}
          >
            <img src={elexModalBannernew} alt="" style={{ width: "100%" }} />
          </div>
          <FaTimes
            onClick={handleClose}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
        </Modal>
      )}
      <div style={{ textAlign: "center" }}>
        <div>
          <Carousel
            data={data}
            time={3000}
            width="100%"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="contain" // Added this
            // initialSlideHeight={500}
            thumbnails={false}
            showThumbs={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "100%",
              maxHeight: "100%",
              margin: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExchangeCarousel;
